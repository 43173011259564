@if(text){
  {{ isExpanded ? text : (text | truncate: (maxLength ?? 100)) }}
  <br />
  @if (text.length > (maxLength ?? 100)) {
    <a
      class="a-link d-inline-block"
      href="javascript:void(0)"
      (click)="toggleReadMore($event)">
      {{ isExpanded ? "Read Less" : "Read More" }}
    </a>
  }
}
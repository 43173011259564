@if(label){
  <label class="label-text">{{ label | translate }}</label>
}

@if(type=='user' && user){
  <div class="user-widget d-flex" [ngClass]="userTypeCustomClass">
    <app-user-image
      [imageUrl]="user.profile_image_path"
      [initial]="user.initial"
      [color]="user.color"
      [type]="'small'">
    </app-user-image>
    <span class="user-name ms-1 mt-1">{{user.first_name}} {{user.last_name}}</span>
  </div>
}@else if(type=='users' && users){

  <div class="user-widget d-flex">
    @for(user of users; track user; let index = $index) {
      @if (index < displayUserCount) {
        <app-user-image
          [initial]="user.initial"
          [color]="user.color"
          [type]="'small'">
        </app-user-image>
      }
    }

    <a class="a-link ps-2 dropdown-menu-start cursor-pointer" data-bs-toggle="dropdown">
      @if(hiddenUserCount > 0) {
        +{{ hiddenUserCount }}  View All
      } @else {
        View All
      }
    </a>


    <ul class="dropdown-menu">
      @for(user of users; track user) {
        <li>
          <a class="dropdown-item d-flex align-items-center">
            @if(isUserMinimalDTO(user)) {
              <app-user-image
                [initial]="user.initial"
                [color]="user.color"
                [type]="'small'"
                [firstName]="user.first_name"
                [LastName]="user.last_name">
              </app-user-image>
            }
            @if(!isUserMinimalDTO(user)) {
              <div class="ms-1">{{ user.name }}</div>
            }
          </a>
        </li>
      }
    </ul>
  </div>

}@else if (type=='status' && status) {
  <p class="p-text">
    <span class="{{ getClass(status.color_code || status.color) }}">{{status.title}}</span>
  </p>
}@else if (type=='list' && list) {
  <ul>
    @for(item of list; track item){
      <li>{{ item.item }}</li>
    }
  </ul>
}@else if (type=='document' && document) {
  <p class="p-text" [title]="document.original_name">
    <app-icon [icon]="'pdf-tab'"></app-icon>
    <a [href]="document.downloadUrl" target="_blank" class="p-text a-link d-inline-block cursor-pointer" >
      {{document.original_name | truncate:(maxLength||40)}}
    </a>
  </p>
}@else if (type=='documents' && documents) {

  <p class="p-text">

  @for (document of documents; track $index) {
    <div class="inline-icon-link">
      <app-icon [icon]="'pdf-tab'"></app-icon>
      <a [href]="document.downloadUrl" class="p-text a-link d-inline-block cursor-pointer" >
        {{document.original_name | truncate:(maxLength||40)}}
      </a>
      <br>
  </div>
  }

</p>

}@else if (value === undefined || value === null || value === '') {
  <p class="p-text">{{ value || '--' }}</p>
}@else if(type=='number'){
  <p class="p-text">{{  value }}</p>
}@else if(type=='text'){
  <p class="p-text">{{  value }}</p>
}@else if(type=='datetime'){
  <p class="p-text">{{  value | dateTimeFormat }}</p>
}@else if (type=='para') {
  <p class="p-text">
    <app-read-more [text]="value.toString()" [maxLength]="maxLength"></app-read-more>
  </p>
}
